import React, { useEffect, useState } from "react"
import Layout from "../../components/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import ImgCustom from "../../components/imgCustom/imgCustom"
import BlockSteps from "../../components/blockSteps/BlockSteps"
import BlockTopPage from "../../components/blockTopPage/blockTopPage"
import BlockSolution from "../../components/blockSolution/blockSolution"
import axios from "axios"
import config from "../../utils/config"
import { Placeholder } from "gatsby-plugin-image"
import CountStars from "../../components/countStars/CountStars"
import SliderCustom from "../../components/sliderCustom/sliderCustom"
import utils from "../../utils/utils"
import Seo from "../../components/seo/seo"
import BlockHeroDiagnostic from "../../components/blockHeroDiagnostic/BlockHeroDiagnostic"

class TrustpilotAvis {
  content: string;
  note: Number;
  title: string;
  date: string;

  constructor(content: string, note: Number, title: string, date: string){
    this.content = content;
    this.note = note;
    this.title = title; 
    this.date = date;
  }
}

const index = () => {
  const data = useStaticQuery(graphql`
    query {
      taxonomyTermBlockHeroDiagnostic {
        field_titre
        field_cover {
          alt
        }
        relationships {
          field_cover {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      nodePageQuiSommesNous {
        title
        field_seo_description
        field_seo_titre
        field_titre_job {
          value
        }
        field_sous_titre_professionnel {
          value
        }
        relationships {
          field_hero_header {
            field_titre_hero {
              value
            }
            field_sous_titre {
              value
            }
            field_description_hero {
              value
            }
            field_cover {
              alt
            }
            field_mobile {
              alt
            }
            field_tablette {
              alt
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              field_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              field_tablette {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          field_liste_des_metiers {
            field_nom_du_metier
            field_description_du_metier {
              value
            }
            relationships {
              field_image_ {
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_image_en_mobile {
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
            field_image_ {
              alt
            }
            field_image_en_mobile {
              alt
            }
          }
          field_block_chiffre {
            name
            field_label_first_value
            field_label_second_value
          }
        }
        field_titre_chiffres {
          value
        }
      }
    }
  `)
  const dataPage = data.nodePageQuiSommesNous
  let blockTopPage = utils.mapHeroHeader(data, "nodePageQuiSommesNous")
  blockTopPage = {
    ...blockTopPage,
    wording: {
      ...blockTopPage.wording,
      description:
        data["nodePageQuiSommesNous"].relationships.field_hero_header
          .field_description_hero.value,
    },
  }
  const dataHeroDiagnostic = data.taxonomyTermBlockHeroDiagnostic
  const blockProfessionneldata = {
    title: data.nodePageQuiSommesNous.field_titre_job.value,
    subTitle: data.nodePageQuiSommesNous.field_sous_titre_professionnel.value,
  }
  const blockChiffresdata = {
    title: data.nodePageQuiSommesNous.field_titre_chiffres.value,
    values: data.nodePageQuiSommesNous.relationships.field_block_chiffre,
  }
  // const blockSolution = {
  //   cover:
  //     data.nodePageQuiSommesNous.relationships
  //       .field_liste_des_metiers[0].relationships.field_image_.localFile
  //       .childrenImageSharp[0].gatsbyImageData,
  //   altCover:
  //     data.nodePageQuiSommesNous.relationships
  //       .field_liste_des_metiers[0].field_image_.alt,
  // }
  const [isMobile, setIsMobile] = useState(false)
  const [isTablette, setIsTablette] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const resizeEvent = () => {
    const tablet = 1170
    const mobile = 768

    if (window.innerWidth > tablet) {
      setIsDesktop(true)
      setIsTablette(false)
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= tablet && window.innerWidth >= mobile) {
      setIsDesktop(false)
      setIsTablette(true)
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= mobile) {
      setIsDesktop(false)
      setIsTablette(false)
      setIsMobile(true)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])

  const blockPro = () => {
    return (
      <>
        <div
          className="job-title ta-c"
          dangerouslySetInnerHTML={{
            __html:
              blockProfessionneldata.title + blockProfessionneldata.subTitle,
          }}
        ></div>
        <div className="job-container">
          <div className="job-icon mb-4">
            <span className="icon">
              <img
                src="/static/5824fb450a462875e8e863a3e18e20e0/Protect.png"
                alt=""
                className="w-100 h-100 of-contain"
              ></img>
            </span>
          </div>
          <div className="dashed" />
          <div className="job">
            {data.nodePageQuiSommesNous.relationships.field_liste_des_metiers.map(
              (item, index) => {
                return (
                  <div className="job-card" key={index}>
                    <div className="job-img p-2">
                      <ImgCustom
                        src={
                          isMobile
                            ? item.relationships.field_image_en_mobile.localFile
                                .childrenImageSharp[0].gatsbyImageData
                            : item.relationships.field_image_.localFile
                                .childrenImageSharp[0].gatsbyImageData
                        }
                        alt={item.field_image_.alt}
                      />
                    </div>
                    <div className="job-text px-2 lh-24">
                      <span className="job-name fs-16">
                        {item.field_nom_du_metier}
                      </span>
                      <div
                        className="job-description fs-14"
                        dangerouslySetInnerHTML={{
                          __html: item.field_description_du_metier.value,
                        }}
                      ></div>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </>
    )
  }
  const [dataTrustpilot, setDataTrustpilot] = useState([])
  const [avisTrustpilot, setAvisTrustpilot] = useState([TrustpilotAvis])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [load, setLoad] = useState(false)

  const queryDataTrustpilot = () => {
    axios
      .get("https://api.trustpilot.com/v1/business-units/56b0bfbf0000ff00058867a6/reviews?apikey=Jz94wZ2KncAmyS2dZ3op3BVvTlJTDxbZ&perPage=30")
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            setDataTrustpilot(res.data)
            avisTrustpilot.pop();
            var nbAvisRecolte = 0
            res.data.reviews.forEach((avis) => {
              if (nbAvisRecolte < 3){
                if (avis.text.length > 99 && avis.title.length > 1 && avis.stars >= 4){
                  var date = new Date(avis.experiencedAt)
                  var dateTxt = "Abonnée depuis " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear().toString() //date.getMonth().toString() + " " + date.getFullYear().toString()
                  avisTrustpilot.push(new TrustpilotAvis(avis.text, avis.stars, avis.title, dateTxt))
                  nbAvisRecolte += 1;
                }
              }
            });
            setIsDataLoaded(true)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
  useEffect(() => {
    if (isDataLoaded) {
      setLoad(true)
    }
  }, [isDataLoaded])
  useEffect(() => {
    queryDataTrustpilot()
  }, [])
  return (
    <Layout isViewPromoOnHeader={false}>
      <Seo
        title={dataPage.field_seo_titre}
        description={dataPage.field_seo_description}
        linkCanonical="qui-sommes-nous/"
      />
      <div className="block-page-aboutus">
        {/* Haut de la page */}
        <div className="wrapper">
          <BlockTopPage data={blockTopPage} />
        </div>
        {/* Les métiers */}
        <div className="wrapper py-8">{blockPro()}</div>
        {/* Chiffres clés */}
        <div className="wrapper">
          <BlockSteps
            title={blockChiffresdata.title}
            data={blockChiffresdata.values}
            chiffre={true}
          />
        </div>
        {/* Avis */}
        <div className="avis wrapper py-8">
          <h2 className="ta-c c-4 txt-bold fs-34 ff-gb lh-48">
            Ils nous font <strong className="c-9">confiance</strong>
          </h2>
          {load ? (
            <div className="avis d-f fd-r jc-sb">
              {avisTrustpilot.map((element, index) => (
                <div key={index} className="px-5 py-2 px-md-2 card-avis">
                  <div className="slider-item box-shadow-s br-22 py-3 px-5 bc-w">
                    <CountStars value={element.note} />
                    <p className="ff-gb cg-7 mt-2">{element.title}</p>
                    <p className="ff-gbook cg-7 fs-14 lh-24">
                      {element.content}
                    </p>
                    <p className="cg-3 ff-gm fs-12 lh-16 mb-3">
                      {element.date}
                    </p>
                    <p className="cg-3 ff-gm fs-14 lh-24 d-f ai-c">
                      <span
                        style={{
                          height: "20px",
                          minWidth: "20px",
                          width: "20px",
                          borderRadius: "50%",
                        }}
                        className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                      ></span>
                      {"Avis authentique récolté via Trustpilot"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // )
            <Placeholder />
          )}
        </div>
      </div>

      {/* <BlockHeroDiagnostic data={dataHeroDiagnostic} /> */}
    </Layout>
  )
}

export default index
